import React from "react";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import {Collapse, Drawer, ListItem} from "@mui/material";
import {Link} from "react-router-dom";
import {DASHBOARD, DELIVERIES, DEPARTMENTS, DEVICES, EXPORT, NEW_HOSES, REPAIRS} from "../../router/navigationPaths";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import ConstructionIcon from "@mui/icons-material/Construction";

const drawerWidth = 250;

const NavigationDrawer = () => {
    const [open, setOpen] = React.useState( true );

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <Drawer
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                "& .MuiDrawer-paper": {
                    width: drawerWidth,
                    boxSizing: "border-box",
                    backgroundColor: "transparent",
                    overflowX: "hidden",
                },
            }}
            variant="permanent"
            anchor="left"
        >
            <Toolbar />
            <List>
                <ListItem className={"list-item"} component={Link} to={DASHBOARD.path}>
                    <ListItemIcon>{DASHBOARD.icon}</ListItemIcon>
                    <ListItemText primary={DASHBOARD.name} />
                </ListItem>
                <ListItem className={"list-item"} component={Link} to={DELIVERIES.path}>
                    <ListItemIcon>{DELIVERIES.icon}</ListItemIcon>
                    <ListItemText primary={DELIVERIES.name} />
                </ListItem>

                <ListItem className={"list-item"} onClick={handleClick}>
                    <ListItemIcon>
                        <ConstructionIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Wartungsarbeiten"} />
                    {open ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem sx={{ pl: 4 }} className={"list-item"} component={Link} to={REPAIRS.path}>
                            <ListItemIcon>{REPAIRS.icon}</ListItemIcon>
                            <ListItemText primary={REPAIRS.name}/>
                        </ListItem>
                        <ListItem sx={{ pl: 4 }} className={"list-item"} component={Link} to={NEW_HOSES.path}>
                            <ListItemIcon>{NEW_HOSES.icon}</ListItemIcon>
                            <ListItemText primary={NEW_HOSES.name}/>
                        </ListItem>
                    </List>
                </Collapse>

                <ListItem className={"list-item"} component={Link} to={EXPORT.path}>
                    <ListItemIcon>{EXPORT.icon}</ListItemIcon>
                    <ListItemText primary={EXPORT.name} />
                </ListItem>
            </List>
            <Divider />
            <List>
                <ListItem className={"list-item"} component={Link} to={DEPARTMENTS.path}>
                    <ListItemIcon>{DEPARTMENTS.icon}</ListItemIcon>
                    <ListItemText primary={DEPARTMENTS.name} />
                </ListItem>
                <ListItem className={"list-item"} component={Link} to={DEVICES.path}>
                    <ListItemIcon>{DEVICES.icon}</ListItemIcon>
                    <ListItemText primary={DEVICES.name} />
                </ListItem>
            </List>
        </Drawer>
    );
};

export default NavigationDrawer;
