import React, {useState} from "react";
import DefaultPage from "../shared/DefaultPage";
import DeliveriesDataGrid from "./DeliveriesDataGrid";
import {DatePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import {TextField} from "@mui/material";
import Stack from "@mui/material/Stack";
import {useGetAllOrdersBetweenDates} from "../../hooks/useGetAllOrdersBetweenDates";

const defaultDayFormat = "MM.DD.YYYY";

const defaultStartDate = () => {
    return dayjs().subtract(30, "day").format(defaultDayFormat);
};

const defaultEndDate = () => {
    return dayjs().format(defaultDayFormat);
};

const Deliveries = () => {
    const [datePickerStartDate, setDatePickerStartDate] = useState(defaultStartDate);
    const [datePickerEndDate, setDatePickerEndDate] = useState(defaultEndDate);

    const { deliveries, isSuccess } = useGetAllOrdersBetweenDates( dayjs( datePickerStartDate ).toISOString(), dayjs( datePickerEndDate ).endOf( "day" ).add( 2, "hours" ).toISOString() );

    const calculateMaxEndDate = () => {
        const isEndDateBeforeStartDate = dayjs(datePickerEndDate).isBefore(datePickerStartDate);
        if (!isEndDateBeforeStartDate) {
            return datePickerStartDate;
        }
        return dayjs().format(defaultDayFormat);
    };

    return (
        <DefaultPage>
            <h1>Abgaben</h1>
            <Stack direction={"row"} spacing={2}>
                <DatePicker
                    label="Von"
                    inputFormat="DD.MM.YYYY"
                    maxDate={dayjs()}
                    value={datePickerStartDate}
                    onChange={(newValue) => {
                        setDatePickerStartDate(newValue);
                    }}
                    renderInput={(params) => <TextField size={"small"} {...params} />}
                />
                <DatePicker
                    label="Bis"
                    inputFormat="DD.MM.YYYY"
                    maxDate={dayjs()}
                    minDate={calculateMaxEndDate()}
                    value={datePickerEndDate}
                    onChange={(newValue) => {
                        setDatePickerEndDate(newValue);
                    }}
                    renderInput={(params) => <TextField size={"small"} {...params} />}
                />
            </Stack>
            <DeliveriesDataGrid deliveries={deliveries} isSuccess={isSuccess} />
        </DefaultPage>
    );
};

export default Deliveries;
