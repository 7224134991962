import {makeStyles} from "@material-ui/core/styles";
import React from "react";
import {DataGrid, GridActionsCellItem} from "@mui/x-data-grid";
import {gridLocale} from "../../../i118/GridLocale";
import dayjs from "dayjs";
import DeleteIcon from "@mui/icons-material/Delete";

const useStyles = makeStyles( ( theme ) => ({
    dataGrid: {
        height: 720,
        width: "100%",
    },
    dataGridRemoveBorder: {
        border: "none !important",
    },
}) );

const RepairsDataGrid = ( {
                              repairs = [],
                              isSuccess,
                              selectedRepairFunction,
                              setDeleteOverlayVisibleFunction
                          } ) => {
    const classes = useStyles();

    const countRender = ( params ) => {
        const count = params.value;
        if( count === 0 ) {
            return "-";
        }
        return params.value;
    };

    const dateRender = (params) => {
        return dayjs(params.value).format("DD/MM/YYYY HH:mm");
    };


    const deleteRepair = (row) =>{
        selectedRepairFunction( row );
        setDeleteOverlayVisibleFunction( true );
    }


    const columns = React.useMemo(
        () => [
            {
                field: "hosesCount",
                headerName: "Schläuche",
                renderCell: countRender,
                flex: true,
            },
            {
                field: "hoseCouplingCount",
                headerName: "Kupplungen",
                renderCell: countRender,
                flex: true,
            },
            {
                field: "holesSealedCount",
                headerName: "Löcher versiegelt",
                renderCell: countRender,
                flex: true,
            },
            {
                field: "workerName",
                headerName: "Durchgeführt von",
                flex: true,
            },
            {
                field: "createdAt",
                headerName: "Durchgeführt am",
                renderCell: dateRender,
                flex: true,
            },
            {
                field: "actions",
                type: "actions",
                width: 80,
                getActions: (params) => [<GridActionsCellItem
                        icon={<DeleteIcon/>}
                        label="Löschen"
                        onClick={() => deleteRepair( params.row )}
                />,
                ],
            },
        ],
        [deleteRepair]
    );

    return (
        <div className={classes.dataGrid}>
            <DataGrid
                getRowId={(row) => row.id}
                rows={repairs}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                loading={repairs.length === 0 && !isSuccess}
                localeText={gridLocale}
                className={classes.dataGridRemoveBorder}
            />
        </div>
    );
};

export default RepairsDataGrid;
