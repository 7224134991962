import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {useAuth0} from "@auth0/auth0-react";
import {useMutation, useQueryClient} from "react-query";
import {Overlay} from "../../shared/Overlay";
import {Alert, Grid} from "@mui/material";
import {ValidatorForm} from "react-material-ui-form-validator";
import {Button} from "@material-ui/core";
import Stack from "@mui/material/Stack";
import {CTextField} from "../../shared/input/CTextField";
import Notifications from "../../shared/Notifications";
import {postNewHoses} from "../../../utils/requests/NewHoses";

const useStyles = makeStyles( ( theme ) => ({
    footer: {
        paddingTop: "24px",
        paddingBottom: "32px",
    },
}) );

const hoseTypes = [{
    name: "B20",
    description: "B-Schlauch 20m",
    count: 0,
}, {
    name: "B5",
    description: "B-Schlauch 5m",
    count: 0,
}, {
    name: "C20",
    description: "C-Schlauch 20m",
    count: 0,
}, {
    name: "C15",
    description: "C-Schlauch 15m",
    count: 0,
}, {
    name: "D",
    description: "D-Schlauch",
    count: 0,
},];

const CreateNewHosesOverlay = ( { closeOverlay } ) => {
    const classes = useStyles();

    const { user } = useAuth0();
    const queryClient = useQueryClient();

    const [hoseB20, setHoseB20] = useState( 0 );
    const [hoseB5, setHoseB5] = useState( 0 );
    const [hoseC20, setHoseC20] = useState( 0 );
    const [hoseC15, setHoseC15] = useState( 0 );
    const [hoseD, setHoseD] = useState( 0 );

    const addNewHosesMutation = useMutation( ( newHoseDto ) => postNewHoses( newHoseDto ), {
        onSuccess: () => {
            Notifications.showSuccess( "Die neuen Schläuche wurden erfolgreich hinterlegt." );
            queryClient.refetchQueries( ["getAllNewHosesBetweenDates"] ).then( closeOverlay() );
        },
        onError: ( error ) => {
            Notifications.showError( `Die neuen Schläuche konnten nicht hinterlegt werden!` );
            console.log( error );
        },
    } );

    const buildNewHoseDto = () => {
        return {
            hoses: buildHoseList(),
            workerName: user.name,
            workerId: user.sub,
        };
    };

    const buildHoseList = () => {
        const hoses = [];

        if( hoseB20 !== 0 ) {
            hoses.push( {
                hoseType: "B20",
                count: hoseB20,
            } );
        }
        if( hoseB5 !== 0 ) {
            hoses.push( {
                hoseType: "B5",
                count: hoseB5,
            } );
        }
        if( hoseC20 !== 0 ) {
            hoses.push( {
                hoseType: "C20",
                count: hoseC20,
            } );
        }
        if( hoseC15 !== 0 ) {
            hoses.push( {
                hoseType: "C15",
                count: hoseC15,
            } );
        }
        if( hoseD !== 0 ) {
            hoses.push( {
                hoseType: "D",
                count: hoseD,
            } );
        }
        return hoses;
    };

    return (<Overlay onClose={() => closeOverlay()} headerContent={<h2>Neue Schläuche hinterlegen</h2>} size="s">
                <Alert severity="info">
                    Das Einpflegen neuer Schläuche erfolgt unter dem Namen: <b>{user.name}</b>
                    <br/>
                    Die hinterlegung wird unter dem aktuellen Datum abgelegt!
                </Alert>

                <ValidatorForm onSubmit={() => addNewHosesMutation.mutate( buildNewHoseDto() )}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <CTextField
                                    hasAutofocus={true}
                                    type={"number"}
                                    label={"Anzahl B-Schläuche 20m"}
                                    value={hoseB20}
                                    onChange={( event ) => setHoseB20( event.target.value )}
                                    validators={["minNumber:0"]}
                                    errorMessages={["Die Eingabe darf nicht kleiner als 0 sein!"]}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CTextField
                                    type={"number"}
                                    label={"Anzahl B-Schläuche 5m"}
                                    value={hoseB5}
                                    onChange={( event ) => setHoseB5( event.target.value )}
                                    validators={["minNumber:0"]}
                                    errorMessages={["Die Eingabe darf nicht kleiner als 0 sein!"]}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CTextField
                                    type={"number"}
                                    label={"Anzahl C-Schläuche 20m"}
                                    value={hoseC20}
                                    onChange={( event ) => setHoseC20( event.target.value )}
                                    validators={["minNumber:0"]}
                                    errorMessages={["Die Eingabe darf nicht kleiner als 0 sein!"]}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CTextField
                                    type={"number"}
                                    label={"Anzahl C-Schläuche 15m"}
                                    value={hoseC15}
                                    onChange={( event ) => setHoseC15( event.target.value )}
                                    validators={["minNumber:0"]}
                                    errorMessages={["Die Eingabe darf nicht kleiner als 0 sein!"]}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CTextField
                                    type={"number"}
                                    label={"Anzahl D-Schläuche"}
                                    value={hoseD}
                                    onChange={( event ) => setHoseD( event.target.value )}
                                    validators={["minNumber:0"]}
                                    errorMessages={["Die Eingabe darf nicht kleiner als 0 sein!"]}
                            />
                        </Grid>
                    </Grid>

                    <Stack spacing={2} direction="row" className={classes.footer}>
                        <Button color="primary" variant="contained" type="submit">
                            Anlegen
                        </Button>
                        <Button color="primary" variant="outlined" onClick={() => closeOverlay()}>
                            Abbrechen
                        </Button>
                    </Stack>
                </ValidatorForm>
            </Overlay>);
};

export default CreateNewHosesOverlay;
