import axios from "axios";

export const getTriggerExportData = ({startDate, endDate, onlyRegisteredOrganisations}) => {
    return axios
            .get("https://schlauchwerkstatt.nibucloud.de/fire-hose-management/exportOrganisations", {
                params: {
                    startDate: startDate,
                    endDate: endDate,
                    onlyRegisteredOrganisations: onlyRegisteredOrganisations
                },
            })
            .then((response) => {
                const fileName = response.request.getResponseHeader("Content-Disposition").split("filename=")[1].replaceAll('"', "");
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                return response;
            });
};
