import React, {useState} from "react";
import DefaultPage from "../../shared/DefaultPage";
import {DatePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import {TextField} from "@mui/material";
import {Button} from "@material-ui/core";
import Stack from "@mui/material/Stack";
import CreateNewHosesOverlay from "./CreateNewHosesOverlay";
import NewHosesList from "./NewHosesList";
import DeleteNewHoseOverlay from "./DeleteNewHoseOverlay";

const defaultDayFormat = "MM.DD.YYYY";

const defaultStartDate = () => {
    return dayjs().subtract( 30, "day" ).format( defaultDayFormat );
};

const defaultEndDate = () => {
    return dayjs().format( defaultDayFormat );
};

const NewHoses = () => {
    const [selectedNewHose, setSelectedNewHose] = useState( {} );
    const [isDeleteOverlayVisible, setDeleteOverlayVisible] = useState( false );
    const [isCreateOverlayVisible, setIsCreateOverlayVisible] = useState( false );

    const [datePickerStartDate, setDatePickerStartDate] = useState( defaultStartDate );
    const [datePickerEndDate, setDatePickerEndDate] = useState( defaultEndDate );

    const calculateMaxEndDate = () => {
        const isEndDateBeforeStartDate = dayjs( datePickerEndDate ).isBefore( datePickerStartDate );
        if( !isEndDateBeforeStartDate ) {
            return datePickerStartDate;
        }
        return dayjs().format( defaultDayFormat );
    };

    console.log( selectedNewHose );

    return (<DefaultPage>
                <h1>Neue Schläuche einpflegen</h1>
                <Stack direction={"row"} spacing={2}>
                    <DatePicker
                            label="Von"
                            inputFormat="DD.MM.YYYY"
                            maxDate={dayjs()}
                            value={datePickerStartDate}
                            onChange={( newValue ) => {
                                setDatePickerStartDate( newValue );
                            }}
                            renderInput={( params ) => <TextField size={"small"} {...params} />}
                    />
                    <DatePicker
                            label="Bis"
                            inputFormat="DD.MM.YYYY"
                            maxDate={dayjs()}
                            minDate={calculateMaxEndDate()}
                            value={datePickerEndDate}
                            onChange={( newValue ) => {
                                setDatePickerEndDate( newValue );
                            }}
                            renderInput={( params ) => <TextField size={"small"} {...params} />}
                    />

                    <Button variant="contained" color="primary" onClick={() => setIsCreateOverlayVisible( true )}>
                       Neue Schläuche einpflegen
                    </Button>
                </Stack>

                <NewHosesList
                        startDate={datePickerStartDate}
                        endDate={datePickerEndDate}
                        selectedNewHoseFunction={( item ) => setSelectedNewHose( item )}
                        setDeleteOverlayVisibleFunction={( state ) => setDeleteOverlayVisible( state )}
                />

                {isCreateOverlayVisible && <CreateNewHosesOverlay closeOverlay={() => setIsCreateOverlayVisible( false )}/>}

                {isDeleteOverlayVisible && (<DeleteNewHoseOverlay closeOverlay={() => setDeleteOverlayVisible( false )} newHose={selectedNewHose}/>)}
            </DefaultPage>);
};

export default NewHoses;
