import React from "react";
import { MenuItem } from "@material-ui/core";

export const genderMenuItems = [
    <MenuItem key={0} value={"MALE"}>
        Herr
    </MenuItem>,
    <MenuItem key={1} value={"FEMALE"}>
        Frau
    </MenuItem>,
    <MenuItem key={2} value={"OTHER"}>
        Divers
    </MenuItem>,
];
