import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { Checkbox } from "@material-ui/core";
import { gridLocale } from "../../i118/GridLocale";
import dayjs from "dayjs";
import { Chip } from "@mui/material";
import { DELIVERY_DETAILS } from "../../router/navigationPaths";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    dataGrid: {
        height: 720,
        width: "100%",
    },
    dataGridRemoveBorder: {
        border: "none !important",
    },
}));

//TODO sort Data by newest
const DeliveriesDataGrid = ({ deliveries = [], isSuccess }) => {
    const navigate = useNavigate();
    const classes = useStyles();

    const checkboxRender = (params) => {
        const hasNotes = params.value !== null;
        return <Checkbox checked={hasNotes} disabled={true} color="primary" />;
    };

    const dateRender = (params) => {
        return dayjs(params.value).format("DD/MM/YYYY HH:mm");
    };

    const deliveredFromRender = (params) => {
        const delivery = params.row;
        if (delivery.department === null) {
            return delivery.orderContact.organisation;
        }
        return delivery.department.departmentName;
    };

    const statusRender = (params) => {
        const delivery = params.row;
        if (delivery.department === null) {
            return <Chip label="Nicht registriert" color="error" />;
        }
        return <Chip label="Registriert" color="success" />;
    };

    const deleteUser = React.useCallback(
        (row) => () => {
            navigate(DELIVERY_DETAILS.path.replace(":id", row.id), {
                state: {
                    delivery: row,
                },
            });
        },
        [navigate]
    );

    const columns = React.useMemo(
        () => [
            {
                field: "orderId",
                headerName: "Auftragsnummer",
                flex: true,
            },
            {
                headerName: "Abgegeben von",
                renderCell: deliveredFromRender,
                flex: true,
            },
            {
                field: "department",
                headerName: "Status",
                renderCell: statusRender,
                flex: true,
            },
            {
                field: "notes",
                headerName: "Bemerkungen",
                renderCell: checkboxRender,
                flex: true,
            },
            {
                field: "createdAt",
                headerName: "Abgabedatum",
                renderCell: dateRender,
                flex: true,
            },
            {
                field: "actions",
                type: "actions",
                width: 80,
                getActions: (params) => [
                    <GridActionsCellItem icon={<SearchIcon />} label="Details" onClick={deleteUser(params.row)} />,
                ],
            },
        ],
        [deleteUser]
    );

    return (
        <div className={classes.dataGrid}>
            <DataGrid
                getRowId={(row) => row.id}
                rows={deliveries}
                columns={columns}
                pageSize={10}
                loading={deliveries.length === 0 && !isSuccess}
                localeText={gridLocale}
                className={classes.dataGridRemoveBorder}
            />
        </div>
    );
};

export default DeliveriesDataGrid;
