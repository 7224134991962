import { useAuth0 } from "@auth0/auth0-react";

const ProtectedRoute = ({ children }) => {
    const { isAuthenticated, loginWithRedirect } = useAuth0();

    if (isAuthenticated) {
        return children;
    }
    loginWithRedirect();
};

export default ProtectedRoute;
