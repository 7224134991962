import axios from "axios";

export const getAllDevices = () => {
    return axios.get("https://schlauchwerkstatt.nibucloud.de/fire-hose-management/devices").then((response) => {
        return response.data;
    });
};

export const postDevice = (deviceDto) => {
    return axios.post("https://schlauchwerkstatt.nibucloud.de/fire-hose-management/devices", deviceDto).then((response) => {
        return response;
    });
};

export const putDevice = (deviceId, deviceDto) => {
    return axios.put("https://schlauchwerkstatt.nibucloud.de/fire-hose-management/devices/" + deviceId, deviceDto).then((response) => {
        return response;
    });
};

export const deleteDevice = (deviceId) => {
    return axios.delete("https://schlauchwerkstatt.nibucloud.de/fire-hose-management/devices/" + deviceId).then((response) => {
        return response;
    });
};
