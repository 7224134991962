import axios from "axios";

export const getAllNewHosesBetweenDates = ( startDate, endDate ) => {
    return axios
            .get( "https://schlauchwerkstatt.nibucloud.de/fire-hose-management/newHoses", {
                params: {
                    startDate: startDate,
                    endDate: endDate,
                },
            } )
            .then( ( response ) => {
                return response.data;
            } );
};

export const postNewHoses = ( newHosesDto ) => {
    return axios.post( "https://schlauchwerkstatt.nibucloud.de/fire-hose-management/newHoses", newHosesDto ).then( ( response ) => {
        return response;
    } );
};

export const deleteNewHoses = ( newHoseId ) => {
    return axios.delete( "https://schlauchwerkstatt.nibucloud.de/fire-hose-management/newHoses/" + newHoseId ).then( ( response ) => {
        return response;
    } );
};
