import React from "react";
import {Card, CardActions, CardContent, Grid, Paper} from "@mui/material";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {makeStyles} from "@material-ui/core";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";

const useStyles = makeStyles( ( theme ) => ({
    card: {
        minWidth: 200,
        marginTop: 20,
    },
    infoNotes: {
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
    },
}) );

const hoseTypes = {
    B20: "B-Schlauch 20m",
    B5: "B-Schlauch 5m",
    C20: "C-Schlauch 20m",
    C15: "C-Schlauch 15m",
    D: "D-Schlauch",
};

const HoseCard = ( {
                       key,
                       newHose,
                       selectedNewHoseFunction,
                       setDeleteOverlayVisibleFunction
                   } ) => {
    const classes = useStyles();

    const dateRender = ( params ) => {
        return dayjs( params.value ).format( "DD.MM.YYYY HH:mm" );
    };

    const getHoseType = ( type ) => {
        return hoseTypes[type];
    };

    const renderHoses = () => {
        return newHose.hoses.map( ( hose ) => (<Typography variant="body2">
                    <div className={classes.infoNotes}>
                        <ArrowForwardIcon fontSize="medium"/>
                        <span>
                        {getHoseType( hose.hoseType )} : {hose.count}
                    </span>
                    </div>
                </Typography>) );
    };

    const handleClick = () => {
        selectedNewHoseFunction( newHose );
        setDeleteOverlayVisibleFunction( true );
    };

    return (<Grid item xs={3} key={key}>
                <Paper elevation={2} className={classes.card}>
                    <Card>
                        <CardContent>
                            <Typography variant="body1" component="div">
                                {newHose.workerName}
                            </Typography>
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                {dateRender( newHose.createdAt )}
                            </Typography>
                            {renderHoses()}
                        </CardContent>
                        <CardActions>
                            <IconButton aria-label="delete" onClick={() => handleClick()}>
                                <DeleteIcon/>
                            </IconButton>
                        </CardActions>
                    </Card>
                </Paper>
            </Grid>);
};

export default HoseCard;
