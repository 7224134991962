import axios from "axios";

export const getAllRepairsBetweenDates = (startDate, endDate) => {
    return axios
        .get("https://schlauchwerkstatt.nibucloud.de/fire-hose-management/repairs", {
            params: {
                startDate: startDate,
                endDate: endDate,
            },
        })
        .then((response) => {
            return response.data;
        });
};

export const postRepair = (repairDto) => {
    return axios.post("https://schlauchwerkstatt.nibucloud.de/fire-hose-management/repairs", repairDto).then((response) => {
        return response;
    });
};

export const deleteRepair = (repairId) => {
    return axios.delete("https://schlauchwerkstatt.nibucloud.de/fire-hose-management/repairs/" + repairId).then((response) => {
        return response;
    });
};
