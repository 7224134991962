import React from "react";
import dayjs from "dayjs";
import {useGetAllNewHosesBetweenDates} from "../../../hooks/useGetAllNewHosesBetweenDates";
import HoseCard from "./HoseCard";
import {Grid} from "@mui/material";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles( ( theme ) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing( 2 ),
    },
}) );

const NewHosesList = ( {
                           startDate,
                           endDate,
                           selectedNewHoseFunction,
                           setDeleteOverlayVisibleFunction
                       } ) => {
    const classes = useStyles();

    const {
              newHoses,
              isSuccess
          } = useGetAllNewHosesBetweenDates( dayjs( startDate ).toISOString(), dayjs( endDate ).endOf( "day" ).add( 2, "hours" ).toISOString() );

    return (<div className={classes.root}>
                <Grid container spacing={1} alignItems="stretch">
                    {newHoses.map( ( newHose, index ) => (<HoseCard
                                    key={index}
                                    newHose={newHose}
                                    selectedNewHoseFunction={selectedNewHoseFunction}
                                    setDeleteOverlayVisibleFunction={setDeleteOverlayVisibleFunction}
                            />) )}
                </Grid>
            </div>);
};

export default NewHosesList;
