import {useQuery} from "react-query";
import Notifications from "../components/shared/Notifications";
import {getAllRepairsBetweenDates} from "../utils/requests/Repairs";

export const useGetAllRepairsBetweenDates = (startDate, endDate) => {
    const {
              data: repairs = [],
              refetch,
              isSuccess,
          } = useQuery( ["getAllRepairsBetweenDates", startDate, endDate], () => getAllRepairsBetweenDates( startDate, endDate ), {
        onError: ( error ) => {
            Notifications.showError( "Die Reparaturen konnten nicht abgerufen werden!" );
            console.log( error );
        },
    } );

    return {
        repairs,
        refetch,
        isSuccess,
    };
};
