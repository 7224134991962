import {useMutation, useQueryClient} from "react-query";
import Stack from "@mui/material/Stack";
import {Button} from "@material-ui/core";
import React from "react";
import {Overlay} from "../../shared/Overlay";
import {Alert, AlertTitle} from "@mui/material";
import Notifications from "../../shared/Notifications";
import {deleteRepair} from "../../../utils/requests/Repairs";

const DeleteRepairOverlay = ( {
                                  closeOverlay,
                                  repair
                              } ) => {
    const queryClient = useQueryClient();

    const deleteRepairMutation = useMutation( () => deleteRepair( repair.id ), {
        onSuccess: () => {
            Notifications.showSuccess( "Die Reparatur wurde erfolgreich gelöscht!" );
            queryClient.refetchQueries( ["getAllRepairsBetweenDates"] ).then( closeOverlay() );
        },
        onError: ( error ) => {
            Notifications.showError( "Die Reparatur konnte nicht gelöscht werden!" );
            console.log( error );
        },
    });

    const footerContent = (<Stack spacing={2} direction="row">
                <Button color="primary" variant="contained" type="submit" onClick={() => deleteRepairMutation.mutate()}>
                    Bestätigen
                </Button>
                <Button color="primary" variant="outlined" onClick={() => closeOverlay()}>
                    Abbrechen
                </Button>
            </Stack>);

    return (<Overlay
                    onClose={() => closeOverlay()}
                    headerContent={<h2>Reparatur löschen?</h2>}
                    footerContent={footerContent}
                    size={"s"}
            >
                <Alert severity="info">
                    <AlertTitle>Wichtiger Hinweis</AlertTitle>
                    Durch das löschen der Reparatur wird diese unwiderruflich gelöscht! Ein nachträgliches wiederherstellen
                    der Daten ist nicht möglich!
                    <br/>
                    <br/>
                    Es ist möglich gelöschte Geräte zu einem späteren Zeitpunkt zu exportieren.
                </Alert>
            </Overlay>);
};

export default DeleteRepairOverlay;
