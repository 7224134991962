import { CssBaseline } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Box from "@mui/material/Box";
import NavigationAppBar from "./NavigationAppBar";
import "./defaultPage.scss";
import NavigationDrawer from "./NavigationDrawer";

const DefaultPage = ({ children }) => {
    const { isAuthenticated } = useAuth0();

    return (
        <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <NavigationAppBar />

            {isAuthenticated && <NavigationDrawer />}

            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    bgcolor: "background.default",
                    p: 3,
                }}
            >
                <Toolbar />
                {children}
            </Box>
        </Box>
    );
};
export default DefaultPage;
