import axios from "axios";

export const getAllOrdersBetweenDates = (startDate, endDate) => {
    return axios
            .get( "https://schlauchwerkstatt.nibucloud.de/fire-hose-management/orders", {
                params: {
                    startDate: startDate,
                    endDate: endDate,
                },
            } )
        .then((response) => {
            return response.data;
        });
};
