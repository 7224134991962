import React, {useState} from "react";
import DefaultPage from "../../shared/DefaultPage";
import dayjs from "dayjs";
import {useGetAllRepairsBetweenDates} from "../../../hooks/useGetAllRepairsBetweenDates";
import {DatePicker} from "@mui/x-date-pickers";
import {TextField} from "@mui/material";
import Stack from "@mui/material/Stack";
import RepairsDataGrid from "./RepairsDataGrid";
import {Button} from "@material-ui/core";
import CreateRepairOverlay from "./CreateRepairOverlay";
import DeleteRepairOverlay from "./DeleteRepairOverlay";

const defaultDayFormat = "MM.DD.YYYY";

const defaultStartDate = () => {
    return dayjs().subtract(30, "day").format(defaultDayFormat);
};

const defaultEndDate = () => {
    return dayjs().format(defaultDayFormat);
};

const Repairs = () => {
    const [selectedRepair, setSelectedRepair] = useState({});
    const [isDeleteOverlayVisible, setDeleteOverlayVisible] = useState(false);
    const [isCreateOverlayVisible, setIsCreateOverlayVisible] = useState(false);

    const [datePickerStartDate, setDatePickerStartDate] = useState(defaultStartDate);
    const [datePickerEndDate, setDatePickerEndDate] = useState(defaultEndDate);

    const { repairs, isSuccess } = useGetAllRepairsBetweenDates(
        dayjs(datePickerStartDate).toISOString(),
        dayjs(datePickerEndDate).endOf("day").add(2, "hours").toISOString()
    );

    const calculateMaxEndDate = () => {
        const isEndDateBeforeStartDate = dayjs(datePickerEndDate).isBefore(datePickerStartDate);
        if (!isEndDateBeforeStartDate) {
            return datePickerStartDate;
        }
        return dayjs().format(defaultDayFormat);
    };

    return (
        <DefaultPage>
            <h1>Reparaturen</h1>
            <Stack direction={"row"} spacing={2}>
                <DatePicker
                    label="Von"
                    inputFormat="DD.MM.YYYY"
                    maxDate={dayjs()}
                    value={datePickerStartDate}
                    onChange={(newValue) => {
                        setDatePickerStartDate(newValue);
                    }}
                    renderInput={(params) => <TextField size={"small"} {...params} />}
                />
                <DatePicker
                    label="Bis"
                    inputFormat="DD.MM.YYYY"
                    maxDate={dayjs()}
                    minDate={calculateMaxEndDate()}
                    value={datePickerEndDate}
                    onChange={(newValue) => {
                        setDatePickerEndDate(newValue);
                    }}
                    renderInput={(params) => <TextField size={"small"} {...params} />}
                />

                <Button variant="contained" color="primary" onClick={() => setIsCreateOverlayVisible(true)}>
                    Reparatur hinzufügen
                </Button>
            </Stack>
            <RepairsDataGrid
                repairs={repairs}
                isSuccess={isSuccess}
                selectedRepairFunction={(item) => setSelectedRepair(item)}
                setDeleteOverlayVisibleFunction={(state) => setDeleteOverlayVisible(state)}
            />

            {isCreateOverlayVisible && <CreateRepairOverlay closeOverlay={() => setIsCreateOverlayVisible( false )}/>}

            {isDeleteOverlayVisible && (<DeleteRepairOverlay closeOverlay={() => setDeleteOverlayVisible( false )} repair={selectedRepair}/>
            )}
        </DefaultPage>
    );
};

export default Repairs;
