import {makeStyles} from "@material-ui/core/styles";
import {useMutation, useQueryClient} from "react-query";
import React, {useState} from "react";
import {useAuth0} from "@auth0/auth0-react";
import {Overlay} from "../../shared/Overlay";
import {Alert, Grid} from "@mui/material";
import {ValidatorForm} from "react-material-ui-form-validator";
import {CTextField} from "../../shared/input/CTextField";
import {Button} from "@material-ui/core";
import Stack from "@mui/material/Stack";
import Notifications from "../../shared/Notifications";
import {postRepair} from "../../../utils/requests/Repairs";

const useStyles = makeStyles((theme) => ({
    footer: {
        paddingTop: "24px",
        paddingBottom: "32px",
    },
}));

const CreateRepairOverlay = ( { closeOverlay } ) => {
    const classes = useStyles();

    const { user } = useAuth0();
    const queryClient = useQueryClient();

    const [hosesCount, setHosesCount] = useState( 0 );
    const [hoseCouplingCount, setHoseCouplingCount] = useState( 0 );
    const [holesSealedCount, setHolesSealedCount] = useState( 0 );

    const addRepairMutation = useMutation( ( repairDto ) => postRepair( repairDto ), {
        onSuccess: () => {
            Notifications.showSuccess( "Die Reparatur wurde erfolgreich erstellt." );
            queryClient.refetchQueries( ["getAllRepairsBetweenDates"] ).then( closeOverlay() );
        },
        onError: (error) => {
            Notifications.showError(`Die Reparatur konnte nicht erstellt werden!`);
            console.log(error);
        },
    });

    const repairDtoBuilder = () => {
        return {
            hosesCount: hosesCount,
            hoseCouplingCount: hoseCouplingCount,
            holesSealedCount: holesSealedCount,
            workerName: user.name,
            workerId: user.sub,
        };
    };

    return (<Overlay onClose={() => closeOverlay()} headerContent={<h2>Reparatur hinzufügen</h2>} size="s">
                <Alert severity="info">
                    Die Listung einer Reparatur erfolgt unter dem Namen: <b>{user.name}</b>
                    <br/>
                    Die hinterlegung wird unter dem aktuellen Datum abgelegt!
                </Alert>

                <ValidatorForm onSubmit={() => addRepairMutation.mutate( repairDtoBuilder() )}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <CTextField
                                    hasAutofocus={true}
                                    type={"number"}
                                    label={"Anzahl Schläuche"}
                                    value={hosesCount}
                                    onChange={( event ) => setHosesCount( event.target.value )}
                                    validators={["minNumber:0"]}
                                    errorMessages={["Die Eingabe darf nicht kleiner als 0 sein!"]}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CTextField
                                    type={"number"}
                                    label={"Anzahl Kupplungen"}
                                    value={hoseCouplingCount}
                                    onChange={( event ) => setHoseCouplingCount( event.target.value )}
                                    validators={["minNumber:0"]}
                                    errorMessages={["Die Eingabe darf nicht kleiner als 0 sein!"]}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CTextField
                                    type={"number"}
                                    label={"Anzahl Löcher versiegelt"}
                                    value={holesSealedCount}
                                    onChange={( event ) => setHolesSealedCount( event.target.value )}
                                    validators={["minNumber:0"]}
                                    errorMessages={["Die Eingabe darf nicht kleiner als 0 sein!"]}
                            />
                        </Grid>
                    </Grid>
                    <Stack spacing={2} direction="row" className={classes.footer}>
                        <Button color="primary" variant="contained" type="submit">
                            Anlegen
                        </Button>
                        <Button color="primary" variant="outlined" onClick={() => closeOverlay()}>
                            Abbrechen
                        </Button>
                    </Stack>
                </ValidatorForm>
            </Overlay>);
};

export default CreateRepairOverlay;
