import axios from "axios";

export const getAllDepartments = () => {
    return axios.get("https://schlauchwerkstatt.nibucloud.de/fire-hose-management/departments").then((response) => {
        return response.data;
    });
};

export const postDepartment = (departmentDto) => {
    return axios.post("https://schlauchwerkstatt.nibucloud.de/fire-hose-management/departments", departmentDto).then((response) => {
        return response;
    });
};

export const deleteDepartment = (departmentId) => {
    return axios.delete("https://schlauchwerkstatt.nibucloud.de/fire-hose-management/departments/" + departmentId).then((response) => {
        return response;
    });
};

export const putDepartment = (departmentId, departmentDto) => {
    return axios
        .put("https://schlauchwerkstatt.nibucloud.de/fire-hose-management/departments/" + departmentId, departmentDto)
        .then((response) => {
            return response;
        });
};
