import {useQuery} from "react-query";
import Notifications from "../components/shared/Notifications";
import {getAllNewHosesBetweenDates} from "../utils/requests/NewHoses";

export const useGetAllNewHosesBetweenDates = ( startDate, endDate ) => {
    const {
              data: newHoses = [],
              refetch,
              isSuccess,
          } = useQuery( ["getAllNewHosesBetweenDates", startDate, endDate], () => getAllNewHosesBetweenDates( startDate, endDate ), {
        onError: ( error ) => {
            Notifications.showError( "Die Schlauchtypen konnten nicht abgerufen werden!" );
            console.log( error );
        },
    } );

    return {
        newHoses,
        refetch,
        isSuccess,
    };
};
