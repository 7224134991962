import { Button, Grid, makeStyles } from "@material-ui/core";
import DefaultPage from "../shared/DefaultPage";
import React, {useState} from "react";
import Typography from "@mui/material/Typography";
import {Card, CardContent, CssBaseline, Paper, TextField} from "@mui/material";
import Notifications from "../shared/Notifications";
import {useMutation} from "react-query";
import {putDevice} from "../../utils/requests/Devices";
import {getTriggerExportData} from "../../utils/requests/Export";
import {DatePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import Stack from "@mui/material/Stack";

const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing.unit * 3,
    },
    container: {
        minHeight: "100vh",
        textAlign: "center",
    },
    card: {
        marginTop: "24px",
    },
}));

const defaultDayFormat = "MM.DD.YYYY";

const defaultStartDate = () => {
    return dayjs().subtract(30, "day").format(defaultDayFormat);
};

const defaultEndDate = () => {
    return dayjs().format(defaultDayFormat);
};

const ExportData = () => {
    const classes = useStyles();

    const [datePickerStartDate, setDatePickerStartDate] = useState(defaultStartDate);
    const [datePickerEndDate, setDatePickerEndDate] = useState(defaultEndDate);

    const buildExportMutation = () =>{

        const dto = {
            startDate: dayjs(datePickerStartDate).toISOString(),
            endDate: dayjs(datePickerEndDate).endOf("day").add(2, "hours").toISOString(),
            onlyRegisteredOrganisations: true
        }

        triggerExportMutation.mutate(dto)
    }

    const triggerExportMutation = useMutation((dto) => getTriggerExportData(dto), {
        onSuccess: () => {
            Notifications.showSuccess("Der Export wurde erfolgreich gestartet");
        },
        onError: (error) => {
            Notifications.showError("Der Export konnte nicht gestartet werden");
            console.log(error);
        },
    });


    const calculateMaxEndDate = () => {
        const isEndDateBeforeStartDate = dayjs(datePickerEndDate).isBefore(datePickerStartDate);
        if (!isEndDateBeforeStartDate) {
            return datePickerStartDate;
        }
        return dayjs().format(defaultDayFormat);
    };


    return (
        <DefaultPage>
            <div className={classes.content}>
                <Grid
                    container
                    spacing={3}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    className={classes.container}
                >
                    <Grid container spacing={2}>
                        <Grid item md={4}></Grid>
                        <Grid item md={4}>
                            <Paper elevation={2}>
                                <Card>
                                    <CardContent>
                                        <Typography variant={"h6"}>Abgaben exportieren</Typography>


                                        <Grid
                                                container
                                                spacing={0}
                                                direction="column"
                                                alignItems="center"
                                                justifyContent="center"
                                        >

                                            <Grid item xs={12}>

                                                <Stack direction={"row"} spacing={2}>
                                                    <DatePicker
                                                            label="Von"
                                                            inputFormat="DD.MM.YYYY"
                                                            maxDate={dayjs()}
                                                            value={datePickerStartDate}
                                                            onChange={(newValue) => {
                                                                setDatePickerStartDate(newValue);
                                                            }}
                                                            renderInput={(params) => <TextField size={"small"} {...params} />}
                                                    />
                                                    <DatePicker
                                                            label="Bis"
                                                            inputFormat="DD.MM.YYYY"
                                                            maxDate={dayjs()}
                                                            minDate={calculateMaxEndDate()}
                                                            value={datePickerEndDate}
                                                            onChange={(newValue) => {
                                                                setDatePickerEndDate(newValue);
                                                            }}
                                                            renderInput={(params) => <TextField size={"small"} {...params} />}
                                                    />
                                                </Stack>
                                            </Grid>
                                        </Grid>

                                        <Typography variant={"body2"} className={classes.card}>
                                            Hier können die Abgaben der einzelnen Organisationen exportiert werden. Alle
                                            Abgaben werden pro Organisation aggregiert und kumuliert. Die Daten werden
                                            in einer CSV-Datei bereitgestellt.
                                        </Typography>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className={classes.card}
                                            onClick={() => buildExportMutation()}
                                        >
                                            Export erstellen
                                        </Button>
                                    </CardContent>
                                </Card>
                            </Paper>
                        </Grid>
                        <Grid item md={4}></Grid>
                    </Grid>
                </Grid>
            </div>
        </DefaultPage>
    );
};
export default ExportData;
